@import "../scss/variables";

$borderColor: #DDDDDD;

#verifyShipmentPageSummaryTable {
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  th {
    width: 60%;
  }
  td {
    width: 40%;
  }
  td, th {
    padding: 10px 10px;
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
  }
}
