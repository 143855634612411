@import 'variables';
@import '~bootstrap/scss/bootstrap-grid';
@import '~antd/dist/antd.css';

body {
  background-color: $appBgColor;
}

.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  min-width: 100vw;
}

#header {
  height: 50px;
  background-color: #ffffff;
  //border-bottom: 1px solid #eeeeee;
  box-shadow: 0 4px 5px -2px #aaaaaa;

  #headerCompanyName {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }
}

#primaryNavBar {
  background-color: #1890ff;
  margin-bottom: 15px;
}

.contentBox {
  background: #ffffff;
  margin: 10px;
  padding: 10px 0;
  border: 1px solid #EEEEEE;
}

.contentBoxWithPadding {
  background: #ffffff;
  margin: 10px;
  padding: 10px;
  border: 1px solid #EEEEEE;
}

.appSidebarDrawer {
  position: relative;
  overflow: auto;
  min-height: 100vh;
}

#appSidebar {
  height: 100vh;
  background-color: #ffffff;
}

.am-list-item .am-input-control input {
  border: 1px solid #40a9ff;
  border-radius: 4px;
  padding: 5px 10px;
}

.buyerOrderFormProductPicker .am-picker-col-item {
  font-size: 14px;
}

.selectPickerListItem .am-list-extra {
  font-size: 14px !important;
  font-style: italic;
  flex-basis: 50% !important;
}

#paymentCollectionPageAccountsTable {
  margin: 15px 0;
  width: 100%;
  border-top: 1px solid #AAAAAA;
  border-left: 1px solid #AAAAAA;
  td, th {
    width: 25%;
    padding: 5px;
    border-bottom: 1px solid #AAAAAA;
    border-right: 1px solid #AAAAAA;
  }
  thead {
    tr {
      text-align: center;
      background-color: #EEEEEE;
      color: #000000;
    }
  }
}

.ant-select {
  font-size: 1.1rem;

  .ant-select-selection--single {
    height: 40px;
  }

  .ant-select-selection__rendered {
    line-height: 40px;
  }

  .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    color: #999999;
  }
}

.ant-select-dropdown-menu-item {
  padding: 15px 12px;
  font-size: 1rem;
}

.ant-input-number-handler-wrap.ant-input-number-handler-wrap {
  display: none !important;
}

$pcfSummaryTableBorder: 1px solid #CCCCCC;

#pcf-summaryTable {
  width: 90%;
  margin: 20px auto;
  border-left: $pcfSummaryTableBorder;
  border-top: $pcfSummaryTableBorder;

  td, th {
    padding: 5px 5px;
    border-bottom: $pcfSummaryTableBorder;
    border-right: $pcfSummaryTableBorder;
  }

  td {
    text-align: right;
  }
}
